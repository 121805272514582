
//table
.table {
  &-cmn-01 {
    width: 100%;
    tr {
      border-bottom: 1px solid #EEE;
    }
    th,
    td {
      padding: 35px 20px;
      font-size: 1.4rem;
      vertical-align: top;
      @include mq() {
        font-size: 1.0rem;
      }
      @include mq() {
        display: block;
        padding: 12px;
      }
    }
    th {
      @include mq() {
        padding-bottom: 0;
      }
    }
  }
}
.table-layout {
  display: table;
  width: 100%;
  & + & {
    margin-top: 40px;
    @include mq() {
      margin-top: 25px;
    }
  }
  &__item {
    display: table-cell;
    vertical-align: top;
  }
}
