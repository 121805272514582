.article {
  &-bread {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    &__item {
      &:after {
        display: inline-block;
        margin: 0 10px;
        width: 10px;
        height: 1px;
        background: #c4c4c4;
        vertical-align: middle;
        content: '';
      }
    }
    &__link {
      color: $orange;
      font-size: 1.2rem;
      vertical-align: middle;
    }
  }
  &-header {
    margin-bottom: 55px;
    @include mq {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 74px;
    }
    &__ttl {
      font-size: 1.6rem;
      font-weight: bold;
      @include mq {
        font-size: 2rem;
      }
    }
    &__date {
      margin-top: 22px;
      color: #808080;
      @include mq {
        margin-top: 0;
      }
    }
  }
  &__img {
    margin-bottom: 25px;
    text-align: center;
    @include mq {
      margin-bottom: 49px;
    }
  }
  &__txt {
    margin-top: 2em;
    line-height: 1.7;
    @include mq {
      line-height: 2;
    }
  }
  &__sec {
    margin-top: 97px;
    @include mq {
      margin-top: 60px;
    }
  }
  &__ttl {
    &-secondary {
      padding-bottom: 8px;
      border-bottom: 1px solid $orange;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.63;
      @include mq {
        padding-bottom: 6px;
        font-size: 1.8rem;
      }
    }
    &-tertiary {
      position: relative;
      margin: 27px 0 23px;
      padding-left: 10px;
      font-weight: bold;
      @include mq {
        margin: 47px 0 34px;
        font-size: 1.6rem;
      }
      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 12px;
        margin-top: -6px;
        background: $orange;
        content: '';
      }
    }
    &-quaternary {
      margin: 26px 0 21px;
      font-weight: bold;
      @include mq {
        margin: 60px 0 25px;
      }
    }
  }
  &__table {
    width: 100%;
    th,
    td {
      padding: 10px;
      border-width: 1px 0;
      border-color: #eaeaea transparent;
      border-style: solid;
      font-size: 1.2rem;
      vertical-align: top;
      @include mq {
        padding: 18px 20px;
        font-size: 1.4rem;
      }
    }
    th {
      background: #f8f8f8;
      color: #808080;
    }
    th,
    td {
      & + th,
      & + td {
        border-left: 1px solid #eaeaea;
      }
    }
    thead {
      th {
        font-weight: bold;
      }
    }
    tbody {
      th {
        width: 110px;
        @include mq {
          width: 180px;
        }
      }
    }
    &--fixed {
      table-layout: fixed;
      th,
      td {
        text-align: center;
      }
    }
  }
  &-list {
    margin-top: -10px;
    line-height: 2.4;
    @include mq {
      display: flex;
      flex-wrap: wrap;
      max-width: 554px;
      margin-top: 0;
    }
    &__item {
      @include mq {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }
  &__link {
    margin-top: 86px;
    text-align: center;
    @include mq {
      margin-top: 135px;
    }
  }
}