.recruit {
  &-primary {
    padding: 30px 0;
    @include mq() {
      padding: 7.69% 0 9.23%;
    }
  }
  &-ttl {
    margin-bottom: 35px;
    font-size: 2.0rem;
    line-height: 1;
    @include mq() {
      margin-bottom: 45px;
      font-size: 3.2rem;
    }
    &__sm {
      display: block;
      margin-bottom: 10px;
      font-size: 1.4rem;
    }
  }
}