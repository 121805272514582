//fonts
@import url('https://fonts.googleapis.com/css?family=Crimson+Text');
@import url('https://fonts.googleapis.com/css?family=EB+Garamond');

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: normal;
  src: local('YuGothic-Medium'), /* PostScript Name = localの正式な指定方法 */
  local('Yu Gothic Medium'),     /* PostScript Nameを認識できないChrome用にFull Nameを指定 */
  local('YuGothic-Regular');     /* 游ゴシックMediumが存在しないWindows8.1用 */
}
@font-face {
  font-family: 'MyYuGothicM';
  font-weight: bold;
  src: local('YuGothic-Bold'), /* PostScript Name = localの正式な指定方法 */
  local('Yu Gothic');          /* PostScript Nameを認識できないChrome用にFull Nameを指定 */
}
.pb00 { padding-bottom: 0 !important;}

@for $i from 0 through 20 {
  $num: $i * 5;
  .mt#{$num} {
    margin-top: #{$num}px !important;
  }
  .mb#{$num} {
    margin-bottom: #{$num}px !important;
  }
}
