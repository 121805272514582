.btn {
  &-cmn {
    position: relative;
    display: block;
    width: 85.32%;
    margin: {
      left: auto;
      right: auto;
    };
    padding: 5vw 0;
    background: $orange;
    border: 0;
    color: #FFF;
    text-align: center;
    appearance: none;
    @include mq() {
      max-width: 300px;
      padding: 28px 0;
    }
    &:hover {
      opacity: .6;
    }
    &__arrow {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 20px;
      height: 7px;
      margin-top: -4px;
      fill: #FFF;
    }
    &--sm {
      @include mq() {
        max-width: 200px;
        padding: 15px 0;
      }
    }
    &--lg {
      width: 100%;
      max-width: none;
      padding: 12.8vw 0;
      font-size: 1.8rem;
      @include mq() {
        padding: 42px 0;
      }
      @include mq(xl) {
        padding: 18% 0;
      }
      .btn-cmn__arrow {
        right: 20px;
      }
    }
  }
  &-area {
    margin-top: 8%;
  }
}