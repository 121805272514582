.concept {
  &-intro {
    @include mq() {
      position: relative;
      padding-top: 9.85%;
    }
    &__txt {
      @include mq() {
        position: relative;
        width: 59%;
        margin-left: auto;
        padding: 7.2%;
        background: #FFF;
        z-index: 1;
      }
    }
    &__read {
      margin-bottom: 30px;
      font-size: 2.0rem;
      @include mq() {
        margin-bottom: 9.2%;
      }
    }
    &__img {
      position: relative;
      margin-top: 90px;
      @include mq() {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
      }
      img {
        position: relative;
        width: 88%;
        @include mq() {
          width: 53.85%;
        }
      }
      &:before {
        position: absolute;
        top: 28.19%;
        right: 0;
        width: 48.53%;
        height: 85.9%;
        background: $orange;
        content: '';
        @include mq() {
          top: 12%;
          width: 100%;
          height: 72.8%;
        }
      }
    }
  }
  &-sec {
    padding-top: 90px;
    @include mq() {
      padding-top: 8%;
      overflow: hidden;
    }
    & + & {
      padding-top: 55px;
      @include mq() {
        padding-top: 11.53%;
      }
    }
  }
  &-feature {
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      max-width: none;
    }
    &__img {
      width: calc(100% + 48px);
      margin: 50px -24px 0;
      @include mq() {
        width: auto;
        margin: 0;
        img {
          width: 100%;
          &.concept-feature__img-lg {
            width: 125%;
            max-width: none;
          }
        }
      }
    }
    &__item {
      position: relative;
      &--left {
        @include mq() {
          flex-basis: 40.9%;
          max-width: 40.9%;
          padding-right: 32px;
          order: 0;
        }
      }
      &--right {
        @include mq() {
          flex-basis: 59.1%;
          max-width: 59.1%;
          order: 1;
        }
      }
    }
    &__txt {
      @include mq() {
        padding-right: 30px;
        padding-left: 70px;
      }
    }
    &__rotate {
      @include txt-rotate;
      display: inline-block;
      border-bottom: 1px solid $orange;
      color: $orange;
      @include mq() {
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        border-left: 1px solid $orange;
      }
    }
    &__ttl {
      margin: 25px 0 35px;
      font-size: 2.0rem;
      @include mq() {
        margin: 0 0 40px;
        font-size: 2.8rem;
        line-height: 1;
      }
    }
  }
  &-renovation {
    padding: 40px 0;
    background: #f4f4f4;
    @include mq() {
      padding: 7.69% 0;
    }
    &__inner {
      @include mq() {
        display: flex;
        width: 91%;
        & > div {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
    &__img {
      height: 200px;
      background: url(../img/concept/bg_concept_renovation.jpg) center center / cover no-repeat;
      @include mq() {
        height: auto;
      }
    }
    &__summary {
      padding: 32px 20px 34px;
      background: #ffffff;
      font-size: 1.3rem;
      @include mq() {
        padding: 62px 45px 80px;
        font-size: 1.4rem;
        line-height: 2;
      }
    }
    &__ttl {
      margin-bottom: 27px;
      font-size: 1.6rem;
      @include mq() {
        margin-bottom: 37px;
        font-size: 2.4rem;
      }
    }
    &__btn {
      margin-top: 60px;
      @include mq() {
        margin: 37px auto 0 0;
      }
    }
  }
}