.privacy {
  &__intro {
    margin: 20% 0 15.3%;
    @include mq() {
      margin: 60px 0 55px;
    }
  }
  &-list {
    padding-left: 1.5em;
    &__item {
      padding-left: 1.5em;
      margin-top: 15.3%;
      @include mq() {
        margin-top: 30px;
      }
    }
  }
}