.header {
  position: relative;
  height: 100px;
  padding: 0 24px;
  @include mq() {
    height: 170px;
    padding: 0 65px;
  }
  &-inner {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @include mq() {
      height: 100%;
    }
  }
  &-logo {
    width: 60px;
    @include mq() {
      width: 100px;
    }
  }
  &-trigger {
    position: relative;
    width: 30px;
    height: 23px;
    cursor: pointer;
    z-index: 60;
    @include mq() {
      display: none;
    }
    &__bar {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: $orange;
      transition: $transition;
      transform-origin: center;
      &:nth-child(1) {
        top: 0;
        @at-root .nav-open & {
          transform: rotate(45deg);
        }
      }
      &:nth-child(2) {
        top: 50%;
        @at-root .nav-open & {
          opacity: 0;
        }
      }
      &:nth-child(3) {
        bottom: 0;
        @at-root .nav-open & {
          transform: rotate(-45deg);
        }
      }
      @at-root .nav-open & {
        top: 50%;
        bottom: auto;
        background: #FFF;
      }
    }
  }
}
