.mv {
  position: relative;
  height: 96.8vw;
  overflow: hidden;
  @include mq() {
    height: calc(100vh - 170px);
    min-height: 330px;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    height: 9.01%;
    background: #FFF;
    z-index: 5;
    content: '';
  }
  &--lower {
    @include mq() {
      height: calc(100vh - 220px);
    }
    &:before,
    &:after {
      width: 57.06%;
      @include mq() {
        width: 25%;
      }
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: rgba(0,0,0,.1);
      z-index: 4;
      content: '';
    }
    .top-mv__scroll {
      @include mq() {
        bottom: 50px;
      }
    }
  }
  &__item {
    height: 100%;
    background-size: cover;
    background-position: center center;
  }
  &__ttl {
    position: absolute;
    right: 8%;
    bottom: -2vw;
    color: #FFF;
    font-size: 3.9rem;
    text-align: right;
    line-height: 1;
    z-index: 10;
    @include mq() {
      right: 10%;
      bottom: -14px;
      font-size: 8.4rem;
    }
    &.fixed {
      position: fixed;
    }
    &-sm {
      display: block;
      font-size: 1.2rem;
      font-weight: bold;
      @include mq() {
        font-size: 1.4rem;
      }
    }
  }
}
