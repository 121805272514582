.top {
  &-mv {
    &__txt {
      display: none;
      @include mq() {
        position: absolute;
        top: 17.1%;
        right: 0;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(35% - 200px);
        min-width: 149px;
        height: 55.22%;
        background: #FFF;
        font-size: 2.6vh;
        line-height: 2.2;
        letter-spacing: .2em;
        font-family: $font-mincho;
        z-index: 6;
        //縦書き
        -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
      }
    }
    &__item {
      height: 88.8vw;
      @include mq() {
        height: calc(100vh - 170px);
      }
    }
    &__btn {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 10;
      @include mq() {
        width: 35%;
        min-width: 349px;
        background: #FFF;
      }
    }
    &__scroll {
      display: none;
      @include mq() {
        position: fixed;
        display: block;
        left: 36px;
        bottom: 40px;
        color: #FFF;
        font-size: 1.1rem;
        line-height: 1;
        z-index: 10;
        transition: $transition;
        &-border {
          position: relative;
          width: 1px;
          height: 40px;
          margin: 0 auto;
          overflow: hidden;
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 1px;
            height: 100%;
            content: '';
          }
          &:before {
            background: #FFF;
            animation: scroll 2.4s infinite normal;
            z-index: 10;
          }
          @keyframes scroll{
            0%{transform:translate3d(0,-100%,0)}
            15%{transform:translate3d(0,-98%,0)}
            85%{transform:translate3d(0,98%,0)}
            100%{transform:translate3d(0,100%,0)}
          }
        }
        &-txt {
          padding: 7px 0;
          writing-mode: vertical-rl;
          transform: rotate(-180deg);
        }
      }
      &.hidden {
        opacity: 0;
        visibility: hidden;
      }
    }
    &__progress {
      position: absolute;
      left: 0;
      bottom: 8vw;
      display: block;
      width: 68vw;
      height: 6px;
      z-index: 5;
      overflow: hidden;
      @include mq() {
        bottom: 0;
        width: 65%;
      }
      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $orange;
        content: '';
        animation: progress-start 4s linear;
        @keyframes progress-start {
          0% {
            width: 0;
          }
          100% {
            width: 100%;
          }
        }
        &.end {
          animation: progress-end 1s linear;
        }
        @keyframes progress-end {
          0% {
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(100%);
          }
        }
      }
    }
    .slick-arrow {
      display: none !important;
    }
  }
  &-service {
    @mixin service-hover {
      @at-root .top-service-list__item:hover & {
        @include mq {
          @content;
        }
      }
    }
    &-list {
      @include mq {
        display: flex;
      }
      &__item {
        position: relative;
        display: flex;
        align-items: center;
        height: 280px;
        overflow: hidden;
        @include mq {
          align-items: flex-end;
          flex-basis: 33.33%;
          max-width: 33.33%;
          height: auto;
        }
        &:before {
          display: block;
          padding-bottom: 130%;
          content: '';
        }
      }
      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        transition: $transition;
        @include service-hover {
          transform: scale(1.1);
        }

        //背景画像に使うキーワード配列
        $bg-category: buy, search, renovation;
        @each $cate in $bg-category {
          &--#{$cate} {
            background-image: url(../img/top/img_service_0#{index($bg-category, $cate)}_sp.jpg);
            @include mq {
              background-image: url(../img/top/img_service_0#{index($bg-category, $cate)}_pc.jpg);
            }
          }
        }
        &:before,
        &:after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          transition: $transition;
          content: '';
        }
        &:before {
          background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 23%,rgba(0,0,0,0.4) 74%,rgba(0,0,0,0.5) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
          @include service-hover {
            opacity: 0;
          }
        }
        &:after {
          background: rgba(0,0,0,.4);
          opacity: 0;
          @include service-hover {
            opacity: 1;
          }
        }
      }
      &__summary {
        position: relative;
        color: #ffffff;
        padding: 0 32px;
        transition: $transition;
        @include mq {
          padding: 0 60px 55px;
        }
        @include service-hover {
          padding-bottom: 140px;
        }
      }
      &__ttl {
        font-size: 2rem;
        @include mq {
          font-size: 2.4rem;
        }
      }
      &__txt {
        margin-top: 1em;
        @include mq {
          height: 5.1em;
        }
      }
    }
    &-btn {
      position: absolute;
      bottom: 55px;
      left: 60px;
      font-size: 1.6rem;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      transform: translateY(50%);
      @include service-hover {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition-delay: $transition;
      }
      &__arrow {
        width: 20px;
        height: 10px;
        fill: #FFF;
      }
    }
  }
  &-concept {
    margin-top: 17.46%;
  }
  &-news {
    &__head {
      &:after {
        left: auto;
        top: 50%;
        right: 0;
        @include mq() {
          top: 12px;
        }
      }
    }
    &__txt {
      padding-left: 0;
      @include mq() {
        padding-left: 20px;
      }
    }
  }
  &-about {
    position: relative;
    padding: 21.33vw 0 13.33vw;
    @include mq {
      padding: 15.7% 0 9.76%;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 87.2%;
      background: #f4f4f4;
      content: '';
      @include mq {
        height: 68.64%;
      }
    }

  }
  &-contents {
    overflow: hidden;
  }
}