/*
#overview
【Block】
*/
.bg {
  &-white {
    background: #FFF;
  }
  &-gray {
    background: #F4F4F4;
  }
}

.sec {
  &-cmn {
    padding: {
      top: 60px;
      bottom: 60px;
    };
    @include mq() {
      padding: {
        top: 8%;
        bottom: 8%;
      };
    }
  }
}

.box {
  &-txt {
    position: relative;
    @include mq() {
      line-height: 2.125;
    }
    &__inner {
      background: #FFF;
      padding: 30px 24px;
      @include mq() {
        padding: 14.5454%;
      }
    }
    &__btn {
      width: 100%;
      margin-top: 40px;
      @include mq() {
        margin: 64px 0 0 0;
      }
    }
    &__txt-rotate {
      @include txt-rotate;
      display: none;
      @include mq() {
        position: absolute;
        display: block;
        right: 20px;
        border-left: 1px solid $orange;
      }
    }
  }
  &-cmn {
    padding: 40px 17px 38px;
    @include mq() {
      padding: 9.090909% 9.090909% 7%;
    }
    & + & {
      margin-top: 20px;
    }
  }
}
.display {
  &-block {
    display: block;
  }
  &-inline-block {
    display: inline-block;
  }
  &-inline {
    display: inline;
  }
}