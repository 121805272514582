.slider-btns {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 32vw;
  @include mq() {
    width: 200px;
  }
  &__item {
    position: relative;
    flex-basis: 50%;
    background: #222;
    cursor: pointer;
    & + & {
      border-left: 1px solid #313131;
    }
    &:before {
      display: block;
      padding-bottom: 100%;
      content: '';
    }
    &:after {
      position: absolute;
      top: 41.83%;
      width: 16.66%;
      height: 16.66%;
      border-top: 2px solid #FFF;
      transform-origin: top;
      transition: $transition;
      content: '';
      @include mq() {
        top: 45px;
        width: 10px;
        height: 10px;
      }
    }
    &:hover:after {
      border-color: $orange;
    }
  }
  &__left:after {
    left: 43.5%;
    border-left: 2px solid #FFF;
    transform: rotate(-45deg);
    @include mq() {
      left: 46px;
    }
  }
  &__right:after {
    left: 41.83%;
    border-right: 2px solid #FFF;
    transform: rotate(45deg);
    @include mq() {
      left: 43px;
    }
  }
}
.sec-intro {
  position: relative;
  margin-top: 80px;
  padding-bottom: 50px;
  @include mq() {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0 0 24px;
  }
  &:before {
    position: absolute;
    top: 40px;
    left: 0;
    width: 80%;
    height: calc(100% - 40px);
    background: #F4F4F4;
    content: '';
    @include mq {
      top: 0;
      width: 70%;
      height: 100%;
    }
  }
  &__img {
    position: relative;
    width: 80%;
    margin: 0 0 0 auto;
    @include mq() {
      flex-basis: 40%;
      max-width: 40%;
      margin: -3.6% 0 0 0;
      order: 1;
    }
    img {
      width: 100%;
    }
  }
  &__inner {
    @include mq() {
      flex-basis: 30%;
      max-width: 30%;
      padding: 6.7% 0 17.23% 0;
      margin: 0 6% 0 auto;
      order: 0;
    }
  }
  &__box {
    position: relative;
    margin-top: 28px;
    @include mq() {
      margin-top: 0;
      font-size: 1.5rem;
      order: 0;
      &--left {
        margin-right: auto;
      }
    }
  }
  &__btn {
    width: 100%;
    margin: 30px auto 0 0;
    @include mq() {
      margin: 50px 0;
    }
  }
  &__ttl {
    position: relative;
    margin-bottom: 8vw;
    padding-bottom: 1.33vw;
    color: $orange;
    font-size: 1.6rem;
    font-family: $font-family-crimson;
    @include mq {
      margin-bottom: 30px;
      padding-bottom: 5px;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 220px;
      max-width: 220px;
      height: 1px;
      background: $orange;
      content: '';
    }
  }
  &__btn {
    width: 53.33vw;
    @include mq {
      width: 100%;
    }
  }
  &__txt {
    display: none;
    @include mq() {
      display: block;
      left: -5.9%;
      bottom: -35px;
    }
  }
}
.txt-float {
  @extend .font-crimson;
  position: absolute;
  font-size: 8.0rem;
  color: #FFF;
  line-height: 1;
  @include mq() {
    font-size: 26.0rem;
  }
}
.content-list {
  @include mq() {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  & + & {
    margin-top: 9.17%;
    @include mq() {
      margin-top: 6%;
    }
  }
  &__item {
    @include mq() {
      flex-basis: 50%;
    }
  }
  &__left {
    @include mq() {
      order: 0;
    }
  }
  &__right {
    @include mq() {
      order: 1;
      padding-top: 3%;
    }
  }
  &__box {
    @include mq(sl) {
      min-height: 560px;
    }
  }
}
.other-links {
  position: relative;
  margin: 0 auto;
  padding: 7.733% 0 24%;
  @include mq() {
    padding: 3.5% 0 6%;
  }
  &__content {
    padding: 0 24px;
    font-size: 1.2rem;
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      padding: 0;
      max-width: 82%;
      margin: 0 auto;
      font-size: 1.4rem;
    }
  }
  &__item {
    position: relative;
    margin-top: 10%;
    z-index: 1;
    @include mq() {
      flex-basis: 47.25%;
      margin-top: 30px;
      & + & {
        margin-left: 5.5%;
      }
    }
  }
  &__btn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    background: #FFF;
    color: $font-color-default;
    font-size: 1.4rem;
    @include mq() {
      max-width: none;
      height: 112px;
    }
    @include mq(lg) {
      height: auto;
      padding: 17.17% 0;
      br {
        display: none;
      }
    }
    &-arrow {
      fill: $orange;
    }
  }
  &__txt {
    margin-top: 3.7%;
    @include mq() {
      margin-top: 17px;
    }
  }
  &__txt-float {
    left: 6.4%;
    bottom: 0;
  }
}
.mask {
  position: relative;
  height: 200px;
  overflow: hidden;
  @include mq() {
    height: auto;
  }
  img {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    @include mq() {
      position: static;
      transform: none;
    }
  }
}
.slide-viewer {
  position: relative;
  &__sec {
    padding-top: 40px;
    @include mq() {
      padding-top: 8%;
    }
  }
  &__content {
    position: relative;
  }
  &__slider {
    @include mq() {
      float: left;
      width: 75%;
    }
  }
  &__box {
    display: table;
    width: 100%;
    height: 25vw;
    margin-top: 5.33%;
    margin-bottom: 15.3%;
    font-size: 1.2rem;
    @include mq() {
      float: left;
      display: block;
      width: 25%;
      height: auto;
      margin: 0;
      font-size: 1.4rem;
    }
    & > div {
      display: table-cell;
      vertical-align: top;
      @include mq() {
        display: block;
      }
    }
  }
  &__txt {
    padding-right: 3%;
    @include mq() {
      padding: 0;
      &-inner {
        padding-left: 18%;
      }
    }
  }
  &__txt-float {
    left: -10.7%;
    bottom: 10.5%;
  }
  &__btn {
    width: 32vw;
    @include mq() {
      position: absolute;
      bottom: 0;
      width: 200px;
      transform: translateX(-50%);
    }
  }
  &__count {
    display: none;
    @include mq() {
      display: block;
      margin: -10px 0 15px;
      font-size: 1.4rem;
      line-height: 1;
    }
    &-lg {
      font-size: 4.4rem;
    }
  }
  &-col {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    max-width: 825px;
    width: 100%;
    @include mq() {
      float: left;
      width: 75%;
    }
    &__item {
      flex-basis: 66.66%;
      max-width: 66.66%;
      & + & {
        flex-basis: 32.72%;
        max-width: 32.72%;
      }
    }
  }
  &__caption {
    position: absolute;
    width: 50vw;
  }
}