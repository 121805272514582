html {
  background: #FFF;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}
body {
  font-size: 1.4rem;
  font-family: $font-family-default;
  color: $font-color-default;
  letter-spacing: 0.1em;
  font-weight: 300;
  line-height: 1.7;
}

img {
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
}
svg {
  max-width: 100%;
  vertical-align: middle;
  backface-visibility: hidden;
}

a {
  text-decoration: none;
}
.alpha-img {
  img {
    transition: $transition;
  }
  &:hover {
    img {
      opacity: .6;
    }
  }
}
input:not([type="radio"]):not([type="checkbox"]),
button,
select,
textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
}

.inner {
  max-width: 1600px;
  padding: {
    left: 24px;
    right: 24px;
  };
  margin: {
    left: auto;
    right: auto;
  };
  @include mq() {
    padding:{
      left: 50px;
      right: 50px;
    };
  }
  @include mq(xl) {
    padding: {
      left: 100px;
      right: 100px;
    };
  }
  &--sm {
    max-width: 1000px;
  }
}
