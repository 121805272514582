.contact {
  @mixin contact-input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background: #f7f7f7;
    border: 1px solid #eaeaea;
    border-radius: 3px !important;
    font-size: 120%;
    @include mq() {
      height: 70px;
      font-size: 150%;
    }
    &.is-error {
      border-color: $orange;
      background: #fff8f4;
    }
  }
  &-input {
    @include contact-input;
  }
  &-textarea {
    @include contact-input;
    height: 60vw;
    padding: {
      top: 10px;
      bottom: 10px;
    };
    resize: none;
    @include mq() {
      height: 300px;
    }
  }
  &-item {
    display: block;
    &__ttl {
      margin-bottom: 8px;
      color: #808080;
      &--required:after {
        margin-left: 10px;
        color: $orange;
        font-size: 85.7%;
        content: '必須';
      }
    }
    & + & {
      margin-top: 30px;
      @include mq() {
        margin-top: 55px;
      }
    }
    &__error {
      margin-top: 5px;
      color: $orange;
      font-size: 1.2rem;
    }
    &__confirm {
      font-size: 1.8rem;
    }
  }
  &-agree {
    margin: 10vw 0;
    text-align: center;
    @include mq() {
      margin: 70px 0;
    }
    br {
      display: block;
      @include mq() {
        display: none;
      }
    }
  }
  &-confirm {
    &__txt {
      margin-bottom: 12vw;
      @include mq() {
        margin-bottom: 90px;
      }
    }
    &__btn {
      margin-top: 12vw;
      @include mq() {
        margin-top: 100px;
      }
    }
  }
  &-finish {
    margin-bottom: 12vw;
    @include mq() {
      margin-bottom: 100px;
      text-align: center;
    }
    &__ttl {
      margin-bottom: 8vw;
      font-size: 1.6rem;
      @include mq() {
        margin-bottom: 30px;
        font-size: 2rem;
      }
    }
  }
}