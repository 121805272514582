.footer {
  position: relative;
  margin-top: 13.38461%;
  background: #222;
  color: #FFF;
  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    height: 50px;
    width: 1px;
    background: $orange;
    content: '';
    transform: translateY(-50%);
  }
  &-contact {
    padding: 11.2vw 0 8.27vw;
    background: #F4F4F4;
    color: $font-color-default;
    @include mq() {
      padding: 6% 0 8%;
    }
    &__ttl {
      font-size: 1.4rem;
      @include mq() {
        font-size: 1.8rem;
        text-align: center;
      }
    }
    &__list {
      @include mq() {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        width: $inner-width;
        margin: 3.8461% auto 0;
        background: #D9D9D9;
      }
    }
    &__item {
      margin-top: 5.33vw;
      @include mq() {
        margin-top: 0;
        flex-basis: 50%;
      }
    }
    &__tel {
      padding: 7.46vw 0 9.86vw;
      background: #D9D9D9;
      font-size: 3.0rem;
      text-align: center;
      line-height: 1.26;
      @include mq() {
        padding: 25px 0 29px;
      }
      @include mq(xl) {
        font-size: 4.0rem;
      }
      &-inner {
        display: inline-block;
        text-align: left;
      }
    }
    &__time {
      display: block;
      margin-top: 1.35vw;
      font-size: 1.3rem;
      @include mq() {
        margin-top: 2px;
      }
    }
  }
  
  &-list {
    padding: {
      top: 7.5vw;
      bottom: 17.33vw;
    };
    font-size: 1.3rem;
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      width: $inner-width;
      margin: 0 auto;
      padding: {
        top: 8%;
        bottom: 8%;
      };
    }
    &__item {
      @include mq() {
        flex-basis: 30.93%;
      }
    }
  }
  &-company {
    color: #C1C1C1;
    font-size: 1.2rem;
    &__ttl {
      color: #FFF;
      font-size: 1.4rem;
      font-weight: bold;
      @include mq() {
        line-height: 1;
      }
    }
    &__copyright {
      margin-top: 2.66vw;
      @include mq() {
        margin-top: 25px;
      }
    }
    &__building {
      margin-top: 2.66vw;
      padding-left: .5em;
      @include mq() {
        display: block;
        padding: 0;
        margin-top: 25px;
      }
    }
  }
  &-nav {
    position: relative;
    margin-top: 7vw;
    padding-top: 5.33vw;
    @include mq() {
      margin-top: 0;
      padding-top: 23px; 
    }
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      content: '';
    }
    &:before {
      width: 100%;
      background: #3C3C3C;
    }
    &:after {
      width: 12.23%;
      background: $orange;
    }
    &__item {
      & + & {
        margin-top: 1.5vw;
        @include mq() {
          margin-top: 10px;
        }
      }
    }
    &__link {
      color: #FFF;
    }
  }
}
