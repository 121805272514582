//animation
.fadeIn { opacity: 0;}
.fadeInUp {
  animation-fill-mode:both;
  animation-duration:1.5s;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px);}
  100% { opacity: 1; transform: translateY(0);}
}

$easing: cubic-bezier(0.86, 0, 0.07, 1);
.slide-zoom {
  .slick-slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 1 !important;
  }
  &__wrap {
    overflow: hidden;
    transition-delay: 1s;
    transition-duration: 0s;
    vertical-align: bottom;
    @at-root .slide-next .slick-current & {
      animation: slide-next .85s $easing;
    }
    @at-root .slide-prev .slick-current & {
      animation: slide-prev .85s $easing;
    }
    @keyframes slide-next {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translate(0);
      }
    }
    @keyframes slide-prev {
      0% {
        transform: translate(-100%);
      }
      100% {
        transform: translate(0);
      }
    }
    
  }
  &__item {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transition-delay: 1.5s;
    transition-duration: 0s;
    @at-root .slide-next .slick-current & {
      animation: slide-inner-next .85s $easing;
      z-index: 9999;
    }
    @at-root .slide-prev .slick-current & {
      animation: slide-inner-prev .85s $easing;
    }
    @keyframes slide-inner-next {
      0% {
        transform: translateX(-15%);
      }
      100% {
        transform: translateX(0);
      }
    }
    @keyframes slide-inner-prev {
      0% {
        transform: translateX(15%);
      }
      100% {
        transform: translateX(0);
      }
    }
    
  }
}
