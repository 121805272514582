.profile {
  &-company {
    &__head {
      @include mq() {
        width: 16.36363%;
      }
    }
    &__address {
      th,
      td {
        display: block;
        font-weight: 300;
        @include mq() {
          display: table-cell;
        }
      }
      th {
        @include mq() {
          width: 105px;
        }
      }
      td {
        margin-top: 10px;
      }
      tr + tr th {
        margin-top: 30px;
      }
    }
  }
  &-access {
    margin-top: 40px;
    @include mq() {
      margin-top: 2.25%;
    }
    &__item {
      position: relative;
      padding-left: 24px;
      margin-top: 20px;
      @include mq() {
        margin-top: 1.5%;
      }
      &:before {
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 1px;
        background: $orange;
        content: '';
      }
    }
    &__map {
      position: relative;
      height: 235px;
      margin-top: 25px;
      @include mq() {
        height: 460px;
        margin-top: 3.6%;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__link {
      margin-top: 32px;
      text-align: center;
      @include mq() {
        margin-top: 10px;
        text-align: right;
      }
    }
    &__ico {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      fill: $orange;
      vertical-align: middle;
    }
  }
  &-msg {
    &__ttl {
      font-size: 1.8rem;
      @include mq() {
        margin-bottom: 85px;
        font-size: 2.6rem;
      }
      br {
        display: none;
        @include mq() {
          display: block;
        }
      }
    }
    &__txt {
      margin: 35px 0;
    }
    &__foot {
      text-align: right;
    }
    &__name {
      margin-top: 6px;
    }
  }
  &-history {
    &__head {
      @include mq() {
        width: 210px;
      }
    }
  }
  &-links {
    padding: 8.56% 0 34.86%;
    background: url(../img/profile/bg_profile_01.jpg) center top / cover;
    @include mq() {
      padding: 40px 0 100px;
    }
    &__item {
      @include mq() {
        margin-top: 50px;
      }
      & + & {
        margin-top: 47px;
        @include mq() {
          margin-top: 50px;
        }
      }
    }
    &__btn {
      br {
        @include mq() {
          display: none;
        }
      }
    }
  }
}