.renovation {
  &-list {
    margin-top: -45px;
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      margin-top: -90px;
    }
    &__item {
      margin-top: 45px;
      @include mq() {
        flex-basis: 45.45454%;
        margin-top: 90px;
        margin-right: 9.090909%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    &__img {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        vertical-align: middle;
        transition: .3s;
      }
      &.js-modal-trigger {
        cursor: pointer;
      }
      &.js-modal-trigger:hover img {
        transform: scale(1.05);
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.25);
        opacity: 0;
        transition: $transition;
        content: '';
      }
      &:hover:after {
        opacity: 1;
      }
    }
    &__ico {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50px;
      height: 50px;
      background: #FFF;
      transition: $transition;
      z-index: 1;
      &:before,
      &:after {
        @include x-mark;
      }
      &:after {
        transform: rotate(90deg);
      }
    }
    &__txt {
      margin-top: 25px;
    }
    &__summary {
      margin-top: 30px;
      padding: 0;
    }
  }
  &-detail {
    padding-bottom: 7vw;
    @include mq() {
      padding-bottom: 60px;
    }
    &:last-child {
      padding-bottom: 12vw;
      @include mq() {
        padding-bottom: 100px;
      }
    }
    &__caption {
      margin-top: 3vw;
      @include mq() {
        margin-top: 18px;
        font-size: 1.6rem;
      }
    }
  }
}