.boxes {
  @include mq {
    display: flex;
    flex-wrap: wrap;
    margin-top: -60px;
  }
  &__col3 {
    margin-top: 30px;
    @include mq {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
  }
  &__col2 {
    & + & {
      margin-top: 30px;
      @include mq() {
        margin-top: 0;
      }
    }
    @include mq {
      flex-basis: 47.27%;
      max-width: 47.27%;
      margin-right: 5.46%;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  &__link {
    position: relative;
    display: block;
    height: 100%;
    color: $font-color-default;
    transition: $transition;
    &:before {
      @include mq {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: $orange;
        transition: $transition;
        transform: scaleX(0);
        transform-origin: left;
        content: '';
      }
    }
    &:hover {
      @include mq {
        background: #ffffff;
        box-shadow: 0 0 20px rgba(0,0,0,.13);
        &:before {
          transform: scaleX(1);
        }
      }
    }
  }
  &__images {
    overflow: hidden;
    img {
      width: 100%;
      transition: $transition;
      @at-root a:hover & {
        transform: scale(1.1);
      }
    }
  }
  &__txt {
    @include mq {
      padding: 16px 20px 22px;
    }
    &--l {
      padding: 24px 25px 35px;
      @include mq {
        padding: 38px 50px 75px;
      }
    }
  }
  &__ttl {
    margin: 15px 0 10px;
    font-weight: bold;
    @include mq {
      margin: 0 0 18px;
      font-size: 1.5rem;
    }
    &--l {
      font-weight: normal;
      margin-bottom: 25px;
      @include mq {
        margin-bottom: 38px;
      }
    }
  }
}