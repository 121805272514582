.ttl {
  &-primary {
    //見出し1
    
  }
  &-secondary {
    //見出し2
    margin-bottom: 40px;
    font-size: 0;
    @include mq() {
      margin-bottom: 5.3846%;
    }
    &__en {
      @extend .font-crimson;
      display: block;
      margin-bottom: 10px;
      font-size: 2.4rem;
      line-height: 1.06;
      @include mq() {
        font-size: 3.0rem;
      }
    }
    &__jp {
      position: relative;
      display: inline-block;
      padding-right: 60px;
      font-size: 1.2rem;
      font-weight: bold;
      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        width: 50px;
        height: 1px;
        background: $orange;
        content: '';
      }
    }
    br {
      @include mq(xl) {
        display: none;
      }
    }
  }
  &-tertiary {
    //見出し3
    color: #9A9A9A;
    font-size: 1.2rem;
    text-align: center;
    line-height: 1;
    &__lg {
      display: block;
      margin-bottom: 2.14%;
      color: $font-color-default;
      font-size: 2.4rem;
      @include mq() {
        margin-bottom: 10px;
        font-size: 3.0rem;
      }
    }
  }
  &-quaternary {
    //見出し4
    display: inline-block;
    margin-bottom: 35px;
    padding-right: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid $orange;
    font-weight: bold;
    @include mq() {
      margin-bottom: 4.5%;
    }
  }
  &-lower {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 176px;
    background: #F7F7F7;
    @include mq() {
      height: 260px;
    }
    span {
      display: block;
      line-height: 1;
    }
    &__jp {
      color: #A7A7A7;
      font-size: 1.2rem;
      @include mq() {
        font-size: 1.4rem;
      }
    }
    &__en {
      margin-top: 2.6%;
      font-size: 2.0rem;
      @include mq() {
        margin-top: 10px;
        font-size: 5.4rem;
      }
    }
  }
}