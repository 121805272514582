.modal {
  &-open {
    overflow: hidden;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.94);
    z-index: 500;
    transition: $transition;
    visibility: hidden;
    opacity: 0;
    @at-root .modal-open & {
      visibility: visible;
      opacity: 1;
    }
  }
  &__close-btn {
    position: fixed;
    top: 5vw;
    left: 5vw;
    bottom: auto;
    right: auto;
    width: 40px;
    height: 40px;
    background: #FFF;
    border: 1px solid #D3D3D3;
    border-radius: 100%;
    z-index: 1200;
    transition: $transition;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    @include mq() {
      top: 25px;
      left: 25px;
      width: 50px;
      height: 50px;
    }
    @at-root .modal-open & {
      visibility: visible;
      opacity: 1;
    }
    &:focus {
      border: 0;
    }
    &:before,
    &:after {
      @include x-mark;
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &-content {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 600;
    transition: .3s;
    transform: translateY(40px);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    &.active {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
      transition: 1s;
      overflow-y: auto;
    }
    &__inner {
      max-width: 1080px;
      margin: 0 auto;
      padding: {
        top: 18vw;
        bottom: 18vw;
      };
      height: 0;
      transition: $transition;
      @include mq() {
        padding: {
          top: 80px;
          bottom: 80px;
        };
      }
      @at-root .active & {
        height: 100%;
        transition: 0s;
      }
    }
  }
}