.gnav {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 100px 10% 0;
  opacity: 0;
  visibility: hidden;
  transition: $transition;
  z-index: 50;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: $transition * 2;
    transform: translateY(-100%);
    content: '';
    @at-root .nav-open & {
      background: #222;
      transform: translateY(0);
    }
    @include mq() {
      content: none;
    }
  }
  @at-root .nav-open & {
    visibility: visible;
    opacity: 1;
  }
  @include mq() {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    visibility: visible;
    opacity: 1;
  }
  &-list {
    position: relative;
    z-index: 60;
    overflow: hidden;
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      overflow: visible;
    }
    &__item {
      border-top: 1px solid #313131;
      transition: $transition;
      visibility: hidden;
      opacity: 0;
      transform: translateX(20%);
      @at-root .nav-open & {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transition-delay: .3s + (.05s * $i);
          }
        }
      }
      @include mq() {
        margin-right: 4.61538vw;
        border: 0;
        font-size: 1.3rem;
        opacity: 1;
        transition-delay: 0s;
        transform: none;
        visibility: visible;
      }
      &.top {
        @include mq() {
          display: none;
        }
      }
      &:last-child {
        padding-top: 30px;
        @include mq() {
          margin: 0;
          padding: 0;
        }
      }
    }
    &__link {
      display: block;
      padding: 19px 25px 19px 0;
      color: #ACACAC;
      font-weight: bold;
      transition: $transition;
      @include mq() {
        position: relative;
        padding: 8px 0;
        margin-right: 1.92vw;
        color: $font-color-default;
        &:hover {
          color: $orange;
        }
        &.current {
          color: $orange;
          &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: $orange;
            content: '';
          }
        }
      }
    }
    &__btn {
      display: block;
      padding: 28px 0;
      background: $orange;
      color: #FFF;
      text-align: center;
      @include mq() {
        width: 140px;
        padding: 16px 0;
      }
    }
  }
}
.nav-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}