$brakepoint: 812px;
//ブレイクポイントをマップ型で定義
$breakpoints: (
  'sm': 'screen and (min-width: 400px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1000px)',
  'xl': 'screen and (min-width: 1200px)',
  'sl': 'screen and (min-width: 1500px)',
) !default;

$font-color-default: #333;

$font-family-default: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "メイリオ", Meiryo, sans-serif;
$font-mincho: YuMincho,'Yu Mincho',serif;
$font-family-crimson: 'Crimson Text', serif;
$font-family-eb: 'EB Garamond', serif;

$transition: .3s;

$orange: #EB4800;

$inner-width: 91%;