/*
#overview
【List】
*/

//list
.list {
  &-cmn {
    margin-bottom: 12.23%;
    @include mq() {
      margin-bottom: 0;
    }
    &__item {
      padding: 20px;
      border-top: 1px solid #EAEAEA;
      border-bottom: 1px solid #EAEAEA;
      @include mq() {
        display: table;
        width: 100%;
        padding: 20px;
      }
      & + & {
        border-top: 0;
      }
    }
    &__head {
      position: relative;
      display: inline-block;
      padding-right: 3%;
      color: #808080;
      @include mq() {
        display: table-cell;
        width: 150px;
      }
      &:after {
        position: absolute;
        left: 0;
        top: calc(100% + 26px);
        width: 2.66vw;
        height: 1px;
        background: $orange;
        content: '';
        @include mq() {
          top: 12px;
          left: auto;
          right: 0;
          width: 10px;
        }
      }
    }
    &__txt {
      margin-top: 15px;
      padding-left: 30px;
      @include mq() {
        display: table-cell;
        padding-left: 20px;
      }
    }
  }
  &-half {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq() {
      display: block;
    }
    &__item {
      flex-basis: 46%;
    }
  }
  &-number {
    padding-left: 2rem;
    &-brackets {
      list-style-type: none;
      &__item {
        position: relative;
        padding-left: 2rem;
      }
      &__number {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &-style {
    &--none {
      list-style-type: none;
    }
  }
  &-bnr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__item {
      flex-basis: 32%;
      margin-top: 20px;
      @include mq() {
        flex-basis: 48%;
        margin-top: 15px;
      }
    }
  }
}
.grid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  &--just-center {
    justify-content: center;
  }
  &--center {
    align-items: center;
  }
  &--between {
    justify-content: space-between;
  }
  &--flex-start {
    justify-content: flex-start;
  }
  &__item02 {
    flex-basis: 48%;
    font-size: 1.3rem;
    &:nth-child(2n) {
      margin-left: 4%;
    }
    &:nth-child(n + 3) {
      margin-top: 5px;
    }
  }
  &02 {
    flex-wrap: wrap;
    justify-content: space-between;
    &--align-center {
      @extend .grid02;
      align-items: center;
    }
    &__item {
      flex-basis: 47.8%;
      &:nth-child(2n) {
        margin-left: 4.4%;
      }
      &:nth-child(n + 3) {
        margin-top: 2.3%;
      }
    }
  }
  &03 {
    flex-wrap: wrap;
    justify-content: space-between;
    &--flex-start {
      @extend .grid03;
      justify-content: flex-start;
    }
    &__item {
      flex-basis: 31%;
      margin-right: 3.5%;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 3.2%;
      }
    }
  }
  &04 {
    justify-content: space-between;
    &__item {
      flex-basis: 19%;
      margin-right: 1.25%;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  &05 {
    justify-content: space-between;
    &__item {
      flex-basis: 18.5%;
      margin-right: 1.875%;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  &06 {
    flex-wrap: wrap;
    justify-content: flex-start;
    &__item {
      flex-basis: 15.11%;
      margin-right: 1.868%;
      &:nth-child(6n) {
        margin-right: 0;
      }
      &:nth-child(n + 7) {
        margin-top: 3.2%;
      }
    }
  }
  &-flex {
    flex-wrap: wrap;
    margin-top: -.35em;
    &__item {
      margin-top: .35em;
      margin-right: .5em;
    }
  }
}

.bread-crumb {
  padding: 15px 0;
  border-top: 1px solid #EDEDED;
  background: #fafafa;
  background: -moz-linear-gradient(top, #fafafa 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #fafafa 0%,#ffffff 100%);
  background: linear-gradient(to bottom, #fafafa 0%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ffffff',GradientType=0 );
  ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
  }
  @include mq() {
    display: none;
  }
  li + li:before {
    margin: 0 10px;
    content: '>';
  }
}

